import { PlatformLocation, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'apps/web-app/src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { NGXLogger } from 'ngx-logger';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EMPTY } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { GraphqlService } from '../../../services/graphql.service';
import * as fromStore from './../../../store';
import { retry } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';

interface Options {
  name: string;
  value?: string;
}
@Component({
  selector: 'pwa-user-profile-window',
  templateUrl: './user-profile-window.component.html',
  styleUrls: ['./user-profile-window.component.scss'],
  providers: [ConfirmationService]
})
export class UserProfileWindowComponent implements OnInit {
  loading: boolean = false;

  updateUserFormGroup: FormGroup;

  options: Options[];
  sensorNodesOption: Options[] = [];
  listForDisabledNotification = [];

  context: any;

  constructor(
    private graphqlService: GraphqlService,
    private nbToastrService: NbToastrService,
    private store: Store,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private translateService: TranslateService,
    private logger: NGXLogger,
    private keycloakService: KeycloakService,
    private platformLocation: PlatformLocation,
    private location: Location,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    // this code is used to close dialog with back button
    this.location.go(this.platformLocation.pathname);
    this.platformLocation.onPopState(() => {
      this.location.go(this.platformLocation.pathname);
      this.ref.close();
    });

    this.context = this.config.data;

    this.options = this.keycloakService.getUserRoles().filter(key => key.includes('lang')).map(lang => ({
      name  : this.translateService.instant(`USERPROFILEWINDOW.${lang.split('-')[0].toUpperCase()}`),
      value: lang.split('-')[0]
    }));

    

    // this.translateService.stream('USERPROFILEWINDOW').subscribe(translate => {
    //   this.options = [
    //     { name: translate.IT, value: 'it' },
    //     { name: translate.EN, value: 'en' },
    //     { name: translate.ES, value: 'es' },
    //     { name: translate.NL, value: 'nl' },
    //     { name: translate.FR, value: 'fr' },
    //     { name: translate.TR, value: 'tr' },
    //   ];
    // });

    

    this.context?.user?.sensorNodes.map(sensorNode => {
      this.sensorNodesOption.push({
        name: sensorNode.name,
        value: sensorNode.serialNumber
      });
    });

    if (this.isAdmin()) {
      this.sensorNodesOption.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
    } else {
      this.sensorNodesOption.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    this.updateUserFormGroup = new FormGroup({
      organization: new FormControl({ value: this.context.user?.organization.name, disabled: true }),
      username: new FormControl({ value: this.context.user.username, disabled: true }),
      name: new FormControl(this.context.user.name, [Validators.required]),
      language: new FormControl({ name: this.translateService.instant(`USERPROFILEWINDOW.${this.context.user?.profiling?.language.toUpperCase()}`), value: this.context.user?.profiling?.language }),
      favoriteSensorNode: new FormControl(
        {
          name: this.context.user?.profiling?.sensorNode != ""
            ? this.context?.user?.sensorNodes?.find(sensorNode => sensorNode.serialNumber == this.context.user?.profiling?.sensorNode)?.name
            : this.context?.user?.sensorNodes?.lenght > 0
              ? this.context?.user?.sensorNodes[0].name
              : "",
          value: this.context.user?.profiling?.sensorNode != ""
            ? this.context.user?.profiling?.sensorNode
            : this.context?.user?.sensorNodes.lenght > 0
              ? this.context?.user?.sensorNodes[0].serialNumber
              : ""
        }
      ),
      emailAlert: new FormControl(this.context?.user?.profiling?.emailNotification.alert),
      emailDisease: new FormControl(this.context?.user?.profiling?.emailNotification.disease),
      webAlert: new FormControl(this.context?.user?.profiling?.webNotification.alert),
      webDisease: new FormControl(this.context?.user?.profiling?.webNotification.disease)
    });
  }

  close() {
    this.ref.close();
  };

  closeDialog() {
    this.confirmationService.close();
  }

  checkNotificationChanges() {
    this.listForDisabledNotification = [];
    if (this.context?.user?.profiling?.webNotification.alert !==
      this.updateUserFormGroup.value.webAlert &&
      this.updateUserFormGroup.value.webAlert === false) {
      this.listForDisabledNotification.push("USERPROFILEWINDOW.WEBNOTIFOFFALERTTHRESHOLD");
    }
    if (this.context?.user?.profiling?.webNotification.disease !==
      this.updateUserFormGroup.value.webDisease &&
      this.updateUserFormGroup.value.webDisease === false) {
      this.listForDisabledNotification.push("USERPROFILEWINDOW.WEBNOTIFOFFALERTDISEASE");
    }
    if (this.context?.user?.profiling?.emailNotification.alert !==
      this.updateUserFormGroup.value.emailAlert &&
      this.updateUserFormGroup.value.emailAlert === false) {
      this.listForDisabledNotification.push("USERPROFILEWINDOW.EMAILNOTIFOFFALERTTHRESHOLD");
    }
    if (this.context?.user?.profiling?.emailNotification.disease !==
      this.updateUserFormGroup.value.emailDisease &&
      this.updateUserFormGroup.value.emailDisease === false) {
      this.listForDisabledNotification.push("USERPROFILEWINDOW.EMAILNOTIFOFFALERTDISEASE");
    }
    if (this.listForDisabledNotification.length > 0) {
      this.showAlertNotification();
    } else {
      this.confirmUpdate();
    }
  }

  showAlertNotification() {
    this.confirmationService.confirm({
      header: this.translateService.instant("USERPROFILEWINDOW.NOTIFICATIONALERTTITLE"),
      message: this.translateService.instant("USERPROFILEWINDOW.NOTIFICATIONALERT"),
      reject: () => {

      }
    });
  }

  confirmUpdate() {
    this.loading = true;

    this.graphqlService.updateUser
      (
        {
          input: {
            username: this.context.user.username,
            updateUserPatch: {
              set: {
                name: this.updateUserFormGroup.get('name').value,
                profiling: {
                  language: this.updateUserFormGroup.get('language').value['value'],
                  sensorNode: this.updateUserFormGroup.get('favoriteSensorNode').value['value'],
                  profileImage: this.context.user.profiling.profileImage,
                  emailNotification: {
                    alert: this.updateUserFormGroup.get('emailAlert').value,
                    disease: this.updateUserFormGroup.get('emailDisease').value
                  },
                  webNotification: {
                    alert: this.updateUserFormGroup.get('webAlert').value,
                    disease: this.updateUserFormGroup.get('webDisease').value
                  }
                }
              }
            }
          }
        }
      )
      .pipe(
        map(res => res?.data?.updateUser),
        retry(3),
        catchError((e) => {
          this.logger.error("UserProfileWindowComponent.confirmUpdate.updateUser.catchError: ", e);
          this.nbToastrService.show(this.translateService.instant("COMMON.ERRORMESSAGE"), this.translateService.instant("COMMON.ERROR"), { status: 'danger', duration: 5000 });
          this.loading = false;
          this.close();
          return EMPTY; // TODO
        }),
        first()
      )
      .subscribe(v => {
        this.logger.debug("UserProfileWindowComponent.confirmUpdate.updateUser: ", v);
        this.nbToastrService.show(this.translateService.instant("USERPROFILEWINDOW.SUCCESSMESSAGE"), this.translateService.instant("COMMON.OK"), { status: 'success', duration: 5000 });
        this.store.dispatch(fromStore.UpdateUser({ user: { ...this.context.user, name: v.name, profiling: { ...this.context.user?.profiling, language: v.profiling.language, sensorNode: v.profiling.sensorNode, emailNotification: v.profiling.emailNotification, webNotification: v.profiling.webNotification } } }));
        this.store.dispatch(fromStore.LoadNotifications({ payload: { username: this.keycloakService.getUsername(), language: this.translateService.currentLang } }))
        this.loading = false;
        this.close();
      });
  }

  isAdmin(): boolean {
    return this.keycloakService.isUserInRole(environment.keycloak.config.adminRole);
  }

}
