<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <i class="eva eva-menu-2-outline"></i>
    </a>
    <a *ngIf="!userPictureOnly" href="#" (click)="navigateHome()">
      <img alt="logo" class="logo"
           src="assets/images/opi_logo-vector_v01.svg">
    </a>
  </div>
  <pwa-select-sensor-node class="sensorNodeSelection"></pwa-select-sensor-node>
  <!-- <nb-select class="selectHeader" fullWidth placeholder="Select node" [(selected)]="selectedNode"
    (selectedChange)="changeSensorNode($event)">
    <nb-option *ngFor="let node of sensorNodes$ | async" [value]="node">Name: {{node.name}} | Serial:
      {{node.serialNumber}}
    </nb-option>
  </nb-select> -->
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <!-- <pwa-layout-direction-switcher class="direction-switcher"></pwa-layout-direction-switcher> -->
</div>


<!-- <div class="container align-self-center selection-snode"> -->
<!-- <div class="row">
  <div class="col-12 col-sm-3 col-md-8">
    <pwa-select-sensor-node></pwa-select-sensor-node>
  </div>
</div> -->
<!-- </div> -->


<div class="header-container">
  <div class="lastDetectionFontSize" *ngIf="isMobile()">
    {{'SENSORSCOMPONENT.LASTDETECTION' |
    translate}}: {{lastMeasureTimestamp}}
  </div>

  <!-- <button nbButton (click)="changeLanguage('en')">EN</button>
    <button nbButton (click)="changeLanguage('it')">IT</button>
    <button nbButton (click)="changeTheme('dark')">DARK</button> -->
  <div *ngIf="isMobile()" style="margin-left: 0.7rem;">
    <pwa-battery-icon [batteryStatus]="batteryStatus">
    </pwa-battery-icon>
  </div>
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
            <nb-search type="rotate-layout"></nb-search>
        </nb-action>
        <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <!-- <nb-action [nbPopover]="mapTemplate" nbPopoverPlacement="bottom" class="fas fa-map-marked-alt">
    </nb-action>
    <ng-template #mapTemplate>
      <div class="container">
        <div class="row">
          <div style="height: 26rem; width: 26rem;">
            <pwa-map [withAccordion]="false" [windowMap]="false" [zoom]="5"></pwa-map>
          </div>
        </div>
      </div>
    </ng-template> -->

    <nb-action class="control-item smallActions" badgeText="{{notificationsNotCompleted === 0 ? '' : notificationsNotCompleted}}"
               badgePosition="top end" badgeStatus="success" [nbPopover]="notificationsTemplate"
               nbPopoverPlacement="bottom">
      <i style="font-size: 1.5rem!Important;" class="fas fa-bell"></i>
    </nb-action>
    <ng-template #notificationsTemplate>
      <pwa-notifications-popover (destroy)="destroyNotificationsPopoverComponent()"></pwa-notifications-popover>
    </ng-template>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" [link]="'/history'"
               (click)="getEventHeader('HeaderComponent.getEventHeader.UserMenu', {type: 'menu'})">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="name | async" showTitle="true"
               title="{{organizationName | async}}" [picture]="picture | async" nbContextMenuTag="my-context-menu"
               shape="semi-round">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
