import { Injectable } from '@angular/core';
import * as graphql from '@pwa/graphql';
import { Observable } from 'rxjs';
import { MutationOptionsAlone, QueryOptionsAlone, SubscriptionOptionsAlone, SubscriptionResult, WatchQueryOptionsAlone } from 'apollo-angular/types';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { QueryRef } from 'apollo-angular';
@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(
    private fetchMeasureGQL: graphql.FetchMeasuresGQL,
    private fetchStatusGQL: graphql.FetchStatusGQL,
    private getAllOrganizationsGQL: graphql.GetAllOrganizationsGQL,
    private fetchUserGQL: graphql.FetchUserGQL,
    private getSensorNode: graphql.GetSensorNodeGQL,
    private lastMeasureGQL: graphql.LastMeasureGQL,
    private lastStatusGQL: graphql.LastStatusGQL,
    private updateUserThresholdGQL: graphql.UpdateUserThresholdGQL,
    private addUserThresholdGQL: graphql.AddUserThresholdGQL,
    private updateUserGQL: graphql.UpdateUserGQL,
    private updateUserAdminGQL: graphql.UpdateUserAdminGQL,
    private getChartDataGQL: graphql.GetChartDataGQL,
    private getAverageChartDataGQL: graphql.GetAverageChartDataGQL,
    private addSensorGQL: graphql.AddSensorGQL,
    private addDiagnosticSensorGQL: graphql.AddDiagnosticSensorGQL,
    private updateSensorGQL: graphql.UpdateSensorGQL,
    private updateDiagnosticSensorGQL: graphql.UpdateDiagnosticSensorGQL,
    private getAllSensorNodesGQL: graphql.GetAllSensorNodesGQL,
    private getAllSensorsGQL: graphql.GetAllSensorsGQL,
    private getAllDiagnosticSensorsGQL: graphql.GetAllDiagnosticSensorsGQL,
    private addSensorNodeGQL: graphql.AddSensorNodeGQL,
    private updateSensorNodeGQL: graphql.UpdateSensorNodeGQL,
    private updateSensorNodeAdminGQL: graphql.UpdateSensorNodeAdminGQL,
    private addUserGQL: graphql.AddUserGQL,
    private addOrganizationGQL: graphql.AddOrganizationGQL,
    private updateOrganizationGQL: graphql.UpdateOrganizationGQL,
    private updateUserPasswordGQL: graphql.UpdateUserPasswordGQL,
    private radiationSumGQL: graphql.RadiationSumGQL,
    private evapotranspirationGQL: graphql.EvapotranspirationGQL,
    private degreeDaysGQL: graphql.DegreeDaysGQL,
    private addDownyMildewSpotGQL: graphql.AddDownyMildewSpotGQL,
    private getDownyMildewPredictionsGQL: graphql.GetDownyMildewPredictionsGQL,
    private dayTimeAverageGQL: graphql.DayTimeAverageGQL,
    private nightTimeAverageGQL: graphql.NightTimeAverageGQL,
    private ephemerisGQL: graphql.EphemerisGQL,
    private getInvalidDataGQL: graphql.GetInvalidDataGQL,
    private getDashboardMeasuresGQL: graphql.GetDashboardMeasuresGQL,
    private getSensorNodesLastPositionGQL: graphql.GetSensorNodesLastPositionGQL,
    private getDiseasePredictionsGQL: graphql.GetDiseasePredictionsGQL,
  ) { }

  fetchUser(variables: graphql.FetchUserQueryVariables, options?: QueryOptionsAlone<graphql.FetchUserQueryVariables, graphql.FetchUserQuery>): Observable<ApolloQueryResult<graphql.FetchUserQuery>> {
    return this.fetchUserGQL.fetch(variables, options);
  }

  updateUserPassword(variables: graphql.UpdateUserPasswordMutationVariables, options?: MutationOptionsAlone<graphql.UpdateUserPasswordMutation, graphql.UpdateUserPasswordMutationVariables>): Observable<FetchResult<graphql.UpdateUserPasswordMutation>> {
    return this.updateUserPasswordGQL.mutate(variables, options);
  }


  addOrganization(variables: graphql.AddOrganizationMutationVariables, options?: MutationOptionsAlone<graphql.AddOrganizationMutation, graphql.AddOrganizationMutationVariables>): Observable<FetchResult<graphql.AddOrganizationMutation>> {
    return this.addOrganizationGQL.mutate(variables, options);
  }

  updateOrganization(variables: graphql.UpdateOrganizationMutationVariables, options?: MutationOptionsAlone<graphql.UpdateOrganizationMutation, graphql.UpdateOrganizationMutationVariables>): Observable<FetchResult<graphql.UpdateOrganizationMutation>> {
    return this.updateOrganizationGQL.mutate(variables, options);
  }

  addSensor(variables: graphql.AddSensorMutationVariables, options?: MutationOptionsAlone<graphql.AddSensorMutation, graphql.AddSensorMutationVariables>): Observable<FetchResult<graphql.AddSensorMutation>> {
    return this.addSensorGQL.mutate(variables, options);
  }

  addDiagnosticSensor(variables: graphql.AddDiagnosticSensorMutationVariables, options?: MutationOptionsAlone<graphql.AddDiagnosticSensorMutation, graphql.AddDiagnosticSensorMutationVariables>): Observable<FetchResult<graphql.AddDiagnosticSensorMutation>> {
    return this.addDiagnosticSensorGQL.mutate(variables, options);
  }

  updateSensor(variables: graphql.UpdateSensorMutationVariables, options?: MutationOptionsAlone<graphql.UpdateSensorMutation, graphql.UpdateSensorMutationVariables>): Observable<FetchResult<graphql.UpdateSensorMutation>> {
    return this.updateSensorGQL.mutate(variables, options);
  }

  updateDiagnosticSensor(variables: graphql.UpdateDiagnosticSensorMutationVariables, options?: MutationOptionsAlone<graphql.UpdateDiagnosticSensorMutation, graphql.UpdateDiagnosticSensorMutationVariables>): Observable<FetchResult<graphql.UpdateDiagnosticSensorMutation>> {
    return this.updateDiagnosticSensorGQL.mutate(variables, options);
  }

  addSensorNode(variables: graphql.AddSensorNodeMutationVariables, options?: MutationOptionsAlone<graphql.AddSensorNodeMutation, graphql.AddSensorNodeMutationVariables>): Observable<FetchResult<graphql.AddSensorNodeMutation>> {
    return this.addSensorNodeGQL.mutate(variables, options);
  }

  updateSensorNodeAdmin(variables: graphql.UpdateSensorNodeAdminMutationVariables, options?: MutationOptionsAlone<graphql.UpdateSensorNodeAdminMutation, graphql.UpdateSensorNodeAdminMutationVariables>): Observable<FetchResult<graphql.UpdateSensorNodeAdminMutation>> {
    return this.updateSensorNodeAdminGQL.mutate(variables, options);
  }

  updateSensorNode(variables: graphql.UpdateSensorNodeMutationVariables, options?: MutationOptionsAlone<graphql.UpdateSensorNodeMutation, graphql.UpdateSensorNodeMutationVariables>): Observable<FetchResult<graphql.UpdateSensorNodeMutation>> {
    return this.updateSensorNodeGQL.mutate(variables, options);
  }

  updateUserThreshold(variables: graphql.UpdateUserThresholdMutationVariables, options?: MutationOptionsAlone<graphql.UpdateUserThresholdMutation, graphql.UpdateUserThresholdMutationVariables>): Observable<FetchResult<graphql.UpdateUserThresholdMutation>> {
    return this.updateUserThresholdGQL.mutate(variables, options);
  }

  addUser(variables: graphql.AddUserMutationVariables, options?: MutationOptionsAlone<graphql.AddUserMutation, graphql.AddUserMutationVariables>): Observable<FetchResult<graphql.AddUserMutation>> {
    return this.addUserGQL.mutate(variables, options);
  }

  updateUserAdmin(variables: graphql.UpdateUserAdminMutationVariables, options?: MutationOptionsAlone<graphql.UpdateUserAdminMutation, graphql.UpdateUserAdminMutationVariables>): Observable<FetchResult<graphql.UpdateUserAdminMutation>> {
    return this.updateUserAdminGQL.mutate(variables, options);
  }

  updateUser(variables: graphql.UpdateUserMutationVariables, options?: MutationOptionsAlone<graphql.UpdateUserMutation, graphql.UpdateUserMutationVariables>): Observable<FetchResult<graphql.UpdateUserMutation>> {
    return this.updateUserGQL.mutate(variables, options);
  }

  addUserThreshold(variables: graphql.AddUserThresholdMutationVariables, options?: MutationOptionsAlone<graphql.AddUserThresholdMutation, graphql.AddUserThresholdMutationVariables>): Observable<FetchResult<graphql.AddUserThresholdMutation>> {
    return this.addUserThresholdGQL.mutate(variables, options);
  }

  subscribeToLastMeasure(variables: graphql.LastMeasureSubscriptionVariables, options?: SubscriptionOptionsAlone<graphql.LastMeasureSubscriptionVariables, graphql.LastMeasureSubscription>): Observable<SubscriptionResult<graphql.LastMeasureSubscription>> {
    return this.lastMeasureGQL.subscribe(variables, options);
  };

  subscribeToLastStatus(variables: graphql.LastStatusSubscriptionVariables, options?: SubscriptionOptionsAlone<graphql.LastStatusSubscriptionVariables, graphql.LastStatusSubscription>): Observable<SubscriptionResult<graphql.LastStatusSubscription>> {
    return this.lastStatusGQL.subscribe(variables, options);
  };

  getChartData(variables: graphql.GetChartDataQueryVariables, options?: QueryOptionsAlone<graphql.GetChartDataQueryVariables, graphql.GetChartDataQuery>): Observable<ApolloQueryResult<graphql.GetChartDataQuery>> {
    return this.getChartDataGQL.fetch(variables, options);
  };

  getAverageChartData(variables: graphql.GetAverageChartDataQueryVariables, options?: QueryOptionsAlone<graphql.GetAverageChartDataQueryVariables, graphql.GetAverageChartDataQuery>): Observable<ApolloQueryResult<graphql.GetAverageChartDataQuery>> {
    return this.getAverageChartDataGQL.fetch(variables, options);
  };

  fetchMeasures(variables: graphql.FetchMeasuresQueryVariables, options?: QueryOptionsAlone<graphql.FetchMeasuresQueryVariables, graphql.FetchMeasuresQuery>): Observable<ApolloQueryResult<graphql.FetchMeasuresQuery>> {
    return this.fetchMeasureGQL.fetch(variables, options);
  };

  fetchMeasuresHistory(variables: graphql.FetchMeasuresQueryVariables, options?: WatchQueryOptionsAlone<graphql.FetchMeasuresQueryVariables, graphql.FetchMeasuresQuery>): QueryRef<graphql.FetchMeasuresQuery, graphql.FetchMeasuresQueryVariables> {
    return this.fetchMeasureGQL.watch(variables, options);
  };

  getDashboardMeasures(variables: graphql.GetDashboardMeasuresQueryVariables, options?: QueryOptionsAlone<graphql.GetDashboardMeasuresQueryVariables, graphql.GetDashboardMeasuresQuery>): Observable<ApolloQueryResult<graphql.GetDashboardMeasuresQuery>> {
    return this.getDashboardMeasuresGQL.fetch(variables, options);
  }

  fetchStatus(variables: graphql.FetchStatusQueryVariables, options?: QueryOptionsAlone<graphql.FetchStatusQueryVariables, graphql.FetchStatusQuery>): Observable<ApolloQueryResult<graphql.FetchStatusQuery>> {
    return this.fetchStatusGQL.fetch(variables, options);
  };

  fetchSensorNode(variables: graphql.GetSensorNodeQueryVariables, options?: QueryOptionsAlone<graphql.GetAllDiagnosticSensorsQueryVariables, graphql.GetAllDiagnosticSensorsQuery>): Observable<ApolloQueryResult<graphql.GetSensorNodeQuery>> {
    return this.getSensorNode.fetch(variables, options);
  };

  getAllOrganizations(variables?: graphql.GetAllOrganizationsQueryVariables, options?: QueryOptionsAlone<graphql.GetAllOrganizationsQueryVariables, graphql.GetAllOrganizationsQuery>): Observable<ApolloQueryResult<graphql.GetAllOrganizationsQuery>> {
    return this.getAllOrganizationsGQL.fetch(variables, options);
  };

  getAllSensorNodes(variables?: graphql.GetAllDiagnosticSensorsQueryVariables, options?: QueryOptionsAlone<graphql.GetAllDiagnosticSensorsQueryVariables, graphql.GetAllDiagnosticSensorsQuery>): Observable<ApolloQueryResult<graphql.GetAllSensorNodesQuery>> {
    return this.getAllSensorNodesGQL.fetch(variables, options);
  };

  getAllSensors(variables?: graphql.GetAllSensorsQueryVariables, options?: QueryOptionsAlone<graphql.GetAllSensorsQueryVariables, graphql.GetAllSensorsQuery>): Observable<ApolloQueryResult<graphql.GetAllSensorsQuery>> {
    return this.getAllSensorsGQL.fetch(variables, options);
  };

  getAllDiagnosticSensors(variables?: graphql.GetAllDiagnosticSensorsQueryVariables, options?: QueryOptionsAlone<graphql.GetAllDiagnosticSensorsQueryVariables, graphql.GetAllDiagnosticSensorsGQL>): Observable<ApolloQueryResult<graphql.GetAllDiagnosticSensorsQuery>> {
    return this.getAllDiagnosticSensorsGQL.fetch(variables, options);
  }

  radiationSum(variables: graphql.RadiationSumQueryVariables, options?: QueryOptionsAlone<graphql.RadiationSumQueryVariables, graphql.RadiationSumGQL>): Observable<ApolloQueryResult<graphql.RadiationSumQuery>> {
    return this.radiationSumGQL.fetch(variables, options);
  }

  evapotranspiration(variables: graphql.EvapotranspirationQueryVariables, options?: QueryOptionsAlone<graphql.EvapotranspirationQueryVariables, graphql.EvapotranspirationGQL>): Observable<ApolloQueryResult<graphql.EvapotranspirationQuery>> {
    return this.evapotranspirationGQL.fetch(variables, options);
  }

  degreeDays(variables: graphql.DegreeDaysQueryVariables, options?: QueryOptionsAlone<graphql.DegreeDaysQueryVariables, graphql.DegreeDaysGQL>): Observable<ApolloQueryResult<graphql.DegreeDaysQuery>> {
    return this.degreeDaysGQL.fetch(variables, options);
  }

  addDownyMildewSpot(variables: graphql.AddDownyMildewSpotMutationVariables, options?: MutationOptionsAlone<graphql.AddDownyMildewSpotMutation, graphql.AddDownyMildewSpotMutationVariables>): Observable<FetchResult<graphql.AddDownyMildewSpotMutation>> {
    return this.addDownyMildewSpotGQL.mutate(variables, options);
  }

  getDownyMildewPredictions(variables: graphql.GetDownyMildewPredictionsQueryVariables, options?: QueryOptionsAlone<graphql.GetDownyMildewPredictionsQueryVariables, graphql.GetDownyMildewPredictionsQuery>): Observable<ApolloQueryResult<graphql.GetDownyMildewPredictionsQuery>> {
    return this.getDownyMildewPredictionsGQL.fetch(variables, options);
  }

  getDayTimeAverage(variables: graphql.DayTimeAverageQueryVariables, options?: QueryOptionsAlone<graphql.DayTimeAverageQueryVariables, graphql.DayTimeAverageQuery>): Observable<ApolloQueryResult<graphql.DayTimeAverageQuery>> {
    return this.dayTimeAverageGQL.fetch(variables, options);
  }

  getNightTimeAverage(variables: graphql.NightTimeAverageQueryVariables, options?: QueryOptionsAlone<graphql.NightTimeAverageQueryVariables, graphql.NightTimeAverageQuery>): Observable<ApolloQueryResult<graphql.NightTimeAverageQuery>> {
    return this.nightTimeAverageGQL.fetch(variables, options);
  }

  getEphemeris(variables: graphql.EphemerisQueryVariables, options?: QueryOptionsAlone<graphql.EphemerisQueryVariables, graphql.EphemerisQuery>): Observable<ApolloQueryResult<graphql.EphemerisQuery>> {
    return this.ephemerisGQL.fetch(variables, options);
  }

  getInvalidData(variables: graphql.GetInvalidDataQueryVariables, options?: QueryOptionsAlone<graphql.GetInvalidDataQueryVariables, graphql.GetInvalidDataQuery>): Observable<ApolloQueryResult<graphql.GetInvalidDataQuery>> {
    return this.getInvalidDataGQL.fetch(variables, options);
  }

  getSensorNodesLastPosition(variables: graphql.GetSensorNodesLastPositionQueryVariables, options?: QueryOptionsAlone<graphql.GetSensorNodesLastPositionQueryVariables, graphql.GetSensorNodesLastPositionQuery>): Observable<ApolloQueryResult<graphql.GetSensorNodesLastPositionQuery>> {
    return this.getSensorNodesLastPositionGQL.fetch(variables, options);

  }

  getDiseasePredictions(
    variables: graphql.GetDiseasePredictionsQueryVariables,
    options?: QueryOptionsAlone<
      graphql.GetDiseasePredictionsQueryVariables,
      graphql.GetDiseasePredictionsQuery
    >,
  ): Observable<ApolloQueryResult<graphql.GetDiseasePredictionsQuery>> {
    return this.getDiseasePredictionsGQL.fetch(variables, options);
  }
}
