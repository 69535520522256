<!-- <div class="container"> -->
<p-table
  [value]="notifications"
  [style]="{ width: '80vw' }"
  [scrollable]="true"
  scrollHeight="60vh"
  [paginator]="true"
  [rows]="10"
  [autoLayout]="false"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'COMMON.PAGINATORLABEL' | translate"
  [rowsPerPageOptions]="rowPageOpt"
>
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="isMobile()">{{ "NOTIFICATIONS.WINDOW.TIMESTAMP" | translate }}</th>
      <th *ngIf="isMobile()" style="text-align: center">
        {{ "NOTIFICATIONS.WINDOW.TYPE" | translate }}
      </th>
      <th>{{ "NOTIFICATIONS.WINDOW.CONTENT" | translate }}</th>
      <th style="text-align: center">{{ "NOTIFICATIONS.WINDOW.CONTENT" | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-notification>
    <tr>
      <td *ngIf="isMobile()" style="text-align: center">
        {{ formatTimestamp(getNotificationTimestamp(notification)) }}
      </td>
      <td *ngIf="isMobile()" style="text-align: center">
        <i [class]="getNotificationIcon(notification)"></i>
      </td>
      <td>{{ notification.content }}</td>
      <td style="text-align: center">
        <i
          *ngIf="notification.completed; else elseBlock"
          class="fas fa-check"
        ></i>
        <ng-template #elseBlock>
          <button
            (click)="handleComplete($event)"
            [value]="notification.id"
            pButton
            type="button"
            label="{{ 'NOTIFICATIONS.WINDOW.COMPLETEBUTTON' | translate}}"
            class="p-button-text"
          ></button>
        </ng-template>
      </td>
    </tr>
  </ng-template>
</p-table>
<!-- </div> -->
