import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { tileLayer, marker, icon, MapOptions, Layer, Icon, Map, LatLng, Marker, FeatureGroup, Control, DomUtil } from 'leaflet';
import { environment } from '../../../../environments/environment';
import * as fromStore from '../../../store';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { distinctUntilChanged, filter, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { isMobile } from '../../utils/breakpoints';
import { Dictionary, Position } from '../../../models';
import { LoadingService } from '../../../services/loading/loading.service';
import { COMPONENTS_LIST } from '../../../models/componentsList';

const MAX_ZOOM = 17;

@Component({
  selector: 'pwa-map',
  templateUrl: './map.component.html',
  styleUrls:['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() withAccordion: boolean = true;
  @Input() windowMap: boolean = true;
  @Input() zoomControl: boolean = true;

  currentMarker: Marker;
  // markersDict: any = {};
  markersDict: Dictionary<Marker> = {};

  mapOptions: MapOptions = {
    layers: [
      tileLayer(
        `${environment.protocol}://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
        {
          minZoom: 2,
          maxZoom: MAX_ZOOM,
          attribution: '...',
        }
      ),
    ],
    zoom: 1.5,
    center: new LatLng(0, 0),
    worldCopyJump: true,
  };

  map: Map;

  layers: Layer[] = [];
  greenIcon: Icon = icon({
    iconUrl: `${environment.protocol}://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png`,
    shadowUrl: `${environment.protocol}://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png`,
    iconSize: [20, 33],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [33, 33],
  });
  redIcon: Icon = icon({
    iconUrl: `${environment.protocol}://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png`,
    shadowUrl: `${environment.protocol}://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png`,
    iconSize: [20, 33],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [33, 33],
  });

  destroy$: Subject<void> = new Subject<void>();

  ZIndexOffset = 10;

  loading$ = new BehaviorSubject<boolean | null>(null);

  constructor(private store: Store<AppState>, private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.init();

    this.loadingService.retryTrigger$.pipe(
      distinctUntilChanged(),
      switchMap(id => this.loadingService.warningError$),
      // Check if component has raised an error message
      filter(warnings => warnings.some(warn => warn.component === COMPONENTS_LIST.MAP)),
      // retry
      tap(() => this.init()),
      // cleanup
      tap(() => this.loadingService.removeWarningsForComponent(COMPONENTS_LIST.MAP))
    )
      .subscribe();
  }

  init(): void {
    this.mapOptions.zoomControl = this.zoomControl;
    this.mapOptions.scrollWheelZoom = this.zoomControl;
    this.mapOptions.doubleClickZoom = this.zoomControl;

    this.store
      .select(fromStore.selectAllSensorNodes)
      .pipe(
        tap(() => this.loading$.next(true)),
        filter((v) => v.length > 0),
        takeUntil(this.destroy$)
      )
      .subscribe((sensorNodes) => {
        sensorNodes.forEach((sensorNode) => {
          if (sensorNode.positions != null) {
            if (sensorNode.positions.lat != null) {
              let currentMarker = marker(
                [
                  Number(sensorNode.positions.lat),
                  Number(sensorNode.positions.long),
                ],
                {
                  icon: this.redIcon,
                  title: sensorNode.name,
                  zIndexOffset: 0,
                }
              ).on('click', () =>
                this.store.dispatch(fromStore.ChangeSelectedSensorNodeSerialNumber({sensorNode : sensorNode.serialNumber}))
              );

              this.layers.push(currentMarker);
              this.markersDict[sensorNode.serialNumber] = currentMarker;
            }
          }
        });
        this.loading$.next(false);
      }, err => {
        this.loadingService.addWarning({
          component: COMPONENTS_LIST.MAP,
          err
        })
        this.loading$.next(false)
      });
  }

  onMapReady(map: Map): void {
    // On change SensorNode change position center
    let ourCustomControl = Control.extend({
      options: {
        position: 'topleft',
      },
      onAdd: () => {
        let container = DomUtil.create(
          'button',
          'leaflet-bar leaflet-control leaflet-control-custom fas fa-redo-alt fa-lg align-self-center text-center w-75 h-75'
        );

        container.style.backgroundColor = 'white';
        // container.style.width = '2.1rem';
        // container.style.height = '2.1rem';
        container.onclick = () => {
          map.setZoom(
            map.getBoundsZoom(new FeatureGroup(this.layers).getBounds()) - 1
          );
        };
        return container;
      },
    });

    map.addControl(new ourCustomControl());

    this.store
      .select(fromStore.selectAllSensorNodes)
      .pipe(
        skipWhile((v) => v.length === 0),
        takeUntil(this.destroy$),
        switchMap((sensorNodes) => {
          sensorNodes.forEach((sensorNode) => {
            if (sensorNode.positions != null) {
              if (sensorNode.positions.lat != null) {
                let currentMarker = marker(
                  [
                    Number(sensorNode.positions.lat),
                    Number(sensorNode.positions.long),
                  ],
                  {
                    icon: this.redIcon,
                    title: sensorNode.name,
                    zIndexOffset: 0,
                  }
                ).on('click', () =>
                  this.store.dispatch(
                    fromStore.ChangeSelectedSensorNodeSerialNumber(
                      {sensorNode : sensorNode.serialNumber}
                    )
                  )
                );

                this.layers.push(currentMarker);
                this.markersDict[sensorNode.serialNumber] = currentMarker;
              }
            }
          });
          return this.store
            .select(fromStore.selectCurrentSensorNodeSerialNumber)
            .pipe(
              skipWhile((v) => v == null),
              takeUntil(this.destroy$)
            );
        })
      )
      .subscribe((currentSensorNodeSerialNumber: any) => {
        for (const [key, value] of Object.entries(this.markersDict)) {
          if (key === currentSensorNodeSerialNumber) {
            this.ZIndexOffset += 10; //this is needed because setting the other markers zIndexOffset to 0 causes a bug which doesn't update the green icon
            this.markersDict[key].setZIndexOffset(this.ZIndexOffset);
            this.markersDict[key].setIcon(this.greenIcon);
          } else {
            this.markersDict[key].setIcon(this.redIcon);
          }
        }

        map.setMaxBounds([
          [85, 180],
          [-85, -180],
        ]);

        if (this.markersDict[currentSensorNodeSerialNumber] != null) {
          const calculatedZoom = Math.min(
            map.getBoundsZoom(new FeatureGroup(this.layers).getBounds()),
            MAX_ZOOM
          );
          const currentZoom = map.getZoom();
          if (currentZoom < calculatedZoom) {
            map.setZoomAround(
              this.markersDict[currentSensorNodeSerialNumber].getLatLng(),
              calculatedZoom
            );
          }
          map.setView(
            this.markersDict[currentSensorNodeSerialNumber].getLatLng()
          );
        }
      });
  }

  onMapReadyForSensorWindow(map: Map): void {
    map.setZoom(17.4);
    map.dragging.disable();
    // On change SensorNode change position center
    this.store
      .select(fromStore.selectCurrentSensorNode)
      .pipe(
        filter((v) => v != undefined),
        takeUntil(this.destroy$)
      )
      .subscribe((sensorNode: any) => {
        this.layers = [];

        if (sensorNode.positions != null && sensorNode.positions != false) {
          let currentMarker = marker(
            [
              Number(sensorNode.positions.lat),
              Number(sensorNode.positions.long),
            ],
            {
              icon: this.greenIcon,
              title: sensorNode.name,
              interactive: false,
              zIndexOffset: this.ZIndexOffset,
            }
          );
          this.layers.push(currentMarker);
          map.panTo(
            new LatLng(
              Number(sensorNode.positions.lat),
              Number(sensorNode.positions.long)
            )
          );
        } else {
          this.layers = [];
          map.setZoom(1.1);
          map.panTo(new LatLng(0, 0));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isMobile(): boolean {
    return isMobile();
  }
}
