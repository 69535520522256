<!-- <div class="container pe-0 ps-0">
  <div class="row g-0 justify-content-between">
    <div class="col-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-center align-self-center pb-2">
      <img style="max-height: 2rem;" src="assets/images/opi_logo-vector_v01.svg">
    </div>
    <div *ngIf="!isMobile()"
      class="col col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-self-center text-center lastDetectionFontSize">
      <div>
        {{'SENSORSCOMPONENT.LASTDETECTION' | translate}}:
      </div>
      <div>
        {{lastMeasureTimestamp}}
      </div>
    </div>
    <div class="col-4 align-self-center text-end me-3">
      <span class="created-by">
        <b><a href="https://evja.eu" target="_blank"><img src="assets/images/evja_logo-vector_v01.svg"
              style="max-height:1.5rem; margin-bottom: 0.2rem"></a></b>
        <span class="lastDetectionFontSize"> ©2021</span>

      </span>
    </div>
  </div>
</div> -->
<div class="container pe-0 ps-0">
  <!-- DESKTOP -->
  <div *ngIf="isMobile();" class="row g-0">
    <div class="col align-self-center ps-3">
      <span class="created-by">
        Created with ♥ by <b><a href="https://evja.eu" target="_blank"><img src="assets/images/evja_logo-vector_v01.svg"
              style="max-height:1.5rem; margin-bottom: 0.2rem"></a></b> ©{{yearNow}}
      </span>
    </div>
    <div class="col-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-center align-self-center">
      <img style="max-height: 2rem;" src="assets/images/opi_logo-vector_v01.svg">
    </div>
  </div>
  <!-- MOBILE -->
  <!-- OPI - lastDetection - evja -->
  <!-- <div *ngIf="!isMobile();" class="row g-0 justify-content-between">
    <div class="col-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-center align-self-center pb-2">
      <img style="max-height: 2rem;" src="assets/images/opi_logo-vector_v01.svg">
    </div>
    <div class="col col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-self-center text-center lastDetectionFontSize">
      <div>
        {{'SENSORSCOMPONENT.LASTDETECTION' | translate}}:
      </div>
      <div>
        {{lastMeasureTimestamp}}
      </div>
    </div>
    <div class="col-4 align-self-center text-end me-3">
      <span class="created-by">
        <b><a href="https://evja.eu" target="_blank"><img src="assets/images/evja_logo-vector_v01.svg"
              style="max-height:1.5rem; margin-bottom: 0.2rem"></a></b>
        <span class="lastDetectionFontSize"> ©2021</span>
      </span>
    </div>
  </div> -->
  <!-- evja- lastDetection- OPI -->
  <div *ngIf="!isMobile();" class="row g-0 justify-content-between">
    <div class="col-4 align-self-center ps-3" style="margin-right: -1rem;">
      <span class="created-by">
        <b><a href="https://evja.eu" target="_blank"><img src="assets/images/evja_logo-vector_v01.svg"
              style="max-height:1.5rem; margin-bottom: 0.2rem"></a></b>
        <span class="lastDetectionFontSize"> ©{{yearNow}}</span>
      </span>
    </div>

    <!-- <div class="col col-md-6 col-lg-6 col-xl-6 col-xxl-6 align-self-center text-center lastDetectionFontSize">
      <div>
        {{'SENSORSCOMPONENT.LASTDETECTION' | translate}}:
      </div>
      <div>
        {{lastMeasureTimestamp}}
      </div>
    </div> -->

    <div class="col-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-center align-self-center pb-2">
      <img style="max-height: 2rem;" src="assets/images/opi_logo-vector_v01.svg">
    </div>
  </div>

</div>
